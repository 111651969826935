<template>
  <section>
    <vx-card>
      <vs-tabs v-model="clubIndex" :color="colorx">
        <vs-tab
          v-for="(club, index) in clubs"
          :key="club.key"
          :label="club.name"
          :icon-pack="club.iconPack || 'feather'"
          :icon="club.icon"
          :class="[index === 0 ? `${club.key}-club` : null]"
          :uk-position-center-left="index !== 0 ? `${club.key}-club` : null"
          @click="colorx = club.color"
        >
          <!-- seach -->
          <vs-row class="py-4">
            <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="7">
              <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="5" class="px-2">
                <v-select
                  v-model="searchSelected"
                  :options="searchOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="minw-100" />
              </vs-col> -->
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="4"
                class="px-2"
              >
                <vs-input
                  class="inputx minw-100"
                  placeholder="Keyword"
                  v-model="params.search"
                />
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="center"
                vs-w="3"
                class="px-2"
              >
                <vs-button type="filled" @click.prevent="handleSearch()"
                  >SEARCH</vs-button
                >
              </vs-col>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-w="5"
            >
              <vs-button
                color="success"
                type="border"
                icon-pack="feather"
                icon="icon-plus"
                :to="{
                  name: 'member.profile.create',
                }"
              >
                CREATE MEMBER
              </vs-button>
            </vs-col>
          </vs-row>
          <!-- seach -->

          <!-- table -->
          <custom-vs-table
            pagination
            stripe
            sst
            hoverFlat
            :data="club.members.data"
            :max-items="club.members.meta.pagination.per_page"
            :total="club.members.meta.pagination.total"
            @change-page="onChangePage"
          >
            <template slot="thead">
              <vs-th class="thead">No</vs-th>
              <vs-th class="thead" sort-key="fullName">Name</vs-th>
              <vs-th class="thead" sort-key="phone">Contact Number</vs-th>
              <vs-th class="thead" sort-key="channel">Contact Channel</vs-th>
              <vs-th class="thead">Date</vs-th>
              <vs-th class="thead">Action</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(member, index) in data" :key="index">
                <vs-td>
                  {{ rowNo(index) }}
                </vs-td>
                <vs-td>
                  {{ lodash.get(member, 'profile.name', '-') }}
                </vs-td>
                <vs-td>
                  {{ lodash.get(member, 'profile.phone_number', '-') }}
                </vs-td>
                <vs-td>
                  {{
                    mergeContactChannel(
                      lodash.get(member, 'profile.contact_channel', '-')
                    )
                  }}
                </vs-td>
                <vs-td>
                  {{ lodash.get(member, 'created_at', '-') }}
                </vs-td>
                <vs-td>
                  <vs-button
                    class="float-left"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-edit-2"
                    :to="{
                      name: 'member.profile.edit',
                      params: {
                        memberId: member.id,
                        profileId: member.profile.id,
                      },
                    }"
                  >
                    Edit
                  </vs-button>
                  <vs-button
                    class="float-left"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-message-square"
                    :to="{
                      name: 'member.profile.edit',
                      params: {
                        memberId: member.id,
                        profileId: member.profile.id,
                      },
                      query: {
                        ['new-case']: true,
                      },
                    }"
                  >
                    New Case
                  </vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </custom-vs-table>
          <!-- table -->

          <vs-row class="py-4">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left">
              <span class="badge p-4 bg-primary">Total: {{ total.total }}</span>
              <span class="badge p-4 ml-3 bg-success"
                >Total Customer: {{ total.total_customer }} ({{
                  (total.total_customer / total.total).toFixed(2)
                }}%)</span
              >
            </vs-col>
          </vs-row>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import listMixin from '@/mixins/listMixin'
import constants from '@/utils/constants'

import CustomVsTable from '@/components/table/CustomVsTable'
// import VSelect from 'vue-select'

export default {
  mixins: [listMixin],
  components: {
    // VSelect,
    CustomVsTable,
  },
  data: () => ({
    // searchOptions: [],
    // searchSelected: null,
    colorx: 'rgb(23, 71, 158)',
    // options: [
    //   {id: 1, label: 'All'},
    //   {id: 2, label: 'Full Name'},
    //   {id: 3, label: 'Contact Number'},
    //   {id: 4, label: 'Status'},
    //   {id: 5, label: 'Contact Channel'},
    //   {id: 6, label: 'Action'}
    // ],
    // selected: {id: 1, label: 'All'},

    club: 'blue',
    clubIndex: 0,
    params: {
      club: 'blue',
      page: 1,
    },
  }),
  computed: {
    ...mapGetters('member', ['clubs', 'total']),
    currentClub () {
      return this.clubs[this.clubIndex]
    },
  },
  methods: {
    ...mapActions('member', ['fetch', 'reset', 'fetchTotal']),

    getClub (club) {
      return this.clubs.find((item) => item.key === club)
    },
    mergeContactChannel (contact_channel) {
      if (this.lodash.isArray(contact_channel)) {
        return contact_channel.join(', ')
      }

      return this.lodash.isEmpty(contact_channel) ? '-' : contact_channel
    },
    async _fetch ({ club, page } = {}) {
      this.locked = true

      if (!this.lodash.isUndefined(club)) {
        this.params.club = club

        const { color } = this.getClub(club)
        this.colorx = color
      }

      if (!this.lodash.isUndefined(page)) {
        this.params.page = page
      }

      // await this.fetch({ club, page })
      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },
    onChangePage (page) {
      // const club = this.currentClub?.key

      this._fetch({ page })
    },
    rowNo (index) {
      const current_page = this.lodash.get(
        this.currentClub,
        'members.meta.pagination.current_page',
        0,
      )
      const per_page = this.lodash.get(
        this.currentClub,
        'members.meta.pagination.per_page',
        1,
      )

      return index + 1 + (current_page > 1 ? (current_page - 1) * per_page : 0)
    },
  },
  beforeMount () {
    if (this.$route.query.club) {
      const club = this.getClub(this.$route.query.club)
      this.clubIndex = this.lodash.findIndex(this.clubs, { key: club.key })
      this.club = club.key
    }

    this.searchOptions = constants.memberSearchOptions
    this.searchSelected = this.lodash.first(this.searchOptions)
    // console.log(this.searchOptions)
    // console.log(this.searchSelected)
  },
  mounted () {
    const { key: club } = this.clubs[this.clubIndex]

    this._fetch({ club })
    this.fetchTotal({ club })
  },

  watch: {
    clubIndex (to) {
      // console.log({ to, clubs: this.clubs })

      this.club = this.clubs[to].key

      this.$router.push({ query: { club: this.club } }).catch(() => {})

      this._fetch({ club: this.club, page: 1 })
      this.fetchTotal({ club: this.club })
    },
    club (to) {
      this.params.club = to
    },
  },
}
</script>

<style lang="scss"></style>

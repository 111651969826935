var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('vx-card',[_c('vs-tabs',{attrs:{"color":_vm.colorx},model:{value:(_vm.clubIndex),callback:function ($$v) {_vm.clubIndex=$$v},expression:"clubIndex"}},_vm._l((_vm.clubs),function(club,index){return _c('vs-tab',{key:club.key,class:[index === 0 ? ((club.key) + "-club") : null],attrs:{"label":club.name,"icon-pack":club.iconPack || 'feather',"icon":club.icon,"uk-position-center-left":index !== 0 ? ((club.key) + "-club") : null},on:{"click":function($event){_vm.colorx = club.color}}},[_c('vs-row',{staticClass:"py-4"},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"center","vs-w":"7"}},[_c('vs-col',{staticClass:"px-2",attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"center","vs-w":"4"}},[_c('vs-input',{staticClass:"inputx minw-100",attrs:{"placeholder":"Keyword"},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1),_c('vs-col',{staticClass:"px-2",attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"center","vs-w":"3"}},[_c('vs-button',{attrs:{"type":"filled"},on:{"click":function($event){$event.preventDefault();return _vm.handleSearch()}}},[_vm._v("SEARCH")])],1)],1),_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"flex-end","vs-align":"center","vs-w":"5"}},[_c('vs-button',{attrs:{"color":"success","type":"border","icon-pack":"feather","icon":"icon-plus","to":{
                name: 'member.profile.create',
              }}},[_vm._v(" CREATE MEMBER ")])],1)],1),_c('custom-vs-table',{attrs:{"pagination":"","stripe":"","sst":"","hoverFlat":"","data":club.members.data,"max-items":club.members.meta.pagination.per_page,"total":club.members.meta.pagination.total},on:{"change-page":_vm.onChangePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var data = ref.data;
return _vm._l((data),function(member,index){
              var _obj;
return _c('vs-tr',{key:index},[_c('vs-td',[_vm._v(" "+_vm._s(_vm.rowNo(index))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.lodash.get(member, 'profile.name', '-'))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.lodash.get(member, 'profile.phone_number', '-'))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.mergeContactChannel( _vm.lodash.get(member, 'profile.contact_channel', '-') ))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.lodash.get(member, 'created_at', '-'))+" ")]),_c('vs-td',[_c('vs-button',{staticClass:"float-left",attrs:{"color":"primary","type":"border","icon-pack":"feather","icon":"icon-edit-2","to":{
                    name: 'member.profile.edit',
                    params: {
                      memberId: member.id,
                      profileId: member.profile.id,
                    },
                  }}},[_vm._v(" Edit ")]),_c('vs-button',{staticClass:"float-left",attrs:{"color":"primary","type":"filled","icon-pack":"feather","icon":"icon-message-square","to":{
                    name: 'member.profile.edit',
                    params: {
                      memberId: member.id,
                      profileId: member.profile.id,
                    },
                    query: ( _obj = {}, _obj['new-case'] = true, _obj ),
                  }}},[_vm._v(" New Case ")])],1)],1)})}}],null,true)},[_c('template',{slot:"thead"},[_c('vs-th',{staticClass:"thead"},[_vm._v("No")]),_c('vs-th',{staticClass:"thead",attrs:{"sort-key":"fullName"}},[_vm._v("Name")]),_c('vs-th',{staticClass:"thead",attrs:{"sort-key":"phone"}},[_vm._v("Contact Number")]),_c('vs-th',{staticClass:"thead",attrs:{"sort-key":"channel"}},[_vm._v("Contact Channel")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Date")]),_c('vs-th',{staticClass:"thead"},[_vm._v("Action")])],1)],2),_c('vs-row',{staticClass:"py-4"},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"left","vs-align":"left"}},[_c('span',{staticClass:"badge p-4 bg-primary"},[_vm._v("Total: "+_vm._s(_vm.total.total))]),_c('span',{staticClass:"badge p-4 ml-3 bg-success"},[_vm._v("Total Customer: "+_vm._s(_vm.total.total_customer)+" ("+_vm._s((_vm.total.total_customer / _vm.total.total).toFixed(2))+"%)")])])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }